import Vue from "../vue.js"

if (document.querySelectorAll("#footer-app").length > 0) {
  new Vue({
    el: "#footer-app",
    computed: {
      copyright() {
        const urlProductId = window.location.pathname.split("/")[2]
        const products = window.__PRODUCTS ? JSON.parse(window.__PRODUCTS) : []
        const product = products.find((p) => p.product_id === urlProductId)
        return product ? product.copyright : "Further Underwriting International"
      },
    },
    mounted() {
      document.getElementById("copyright-value").innerHTML = this.copyright
    },
  })
}
